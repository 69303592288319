<template>
  <div v-if="state === 'init'">
    <sn-loader
      :loading="true"
      :wait-for-animation="true"
      :messages="['Please wait...']"
      class="pt-8"
    />
  </div>
  <div v-else-if="state.includes('choose_expert')">
    <choose-lo
      :activation-code="activationCode"
      :state-definition="current_state_definition"
      @choice-made="handleServicerChoice"
      @customize-title="(value) => { titleText = value }"
    />
  </div>
  <!-- Custom handling for the closing portal (uses custom text from the new auth flow, and adds a back action to one state)-->
  <div v-else-if="(isClosingPortal || isEvault) && ['auth_sign_in', 'auth_input_email', 'auth_send_verification', 'auth_send_verification_email_only', 'auth_waiting_for_verification', 'new_recipient_account', 'auth_complete'].includes(state)">
    <div v-if="state.includes('auth_sign_in')">
      <div
        class="sn-h2 mb-6"
        data-cy="title"
        :style="isMobile ? 'text-align: center' : ''"
      >
        Welcome
      </div>
      <sn-label
        class="mb-2 sn-body-1"
        data-cy="email-label"
        title="Email"
      />
      <sn-text-field
        id="email"
        v-model="current_state_definition.fields[0].response"
        type="email"
        data-cy="email-field"
        :autofocus="true"
        :error-messages="current_state_definition.fields[0].error ? current_state_definition.fields[0].error : []"
        :disabled="current_state_definition.fields[0].disabled"
        @focus="clearErrors(0)"
        @blur="checkErrors(0)"
        @keydown.enter="keydownEnter()"
      />
      <sn-label
        class="mb-2 mt-4 sn-body-1"
        data-cy="password-label"
        title="Password"
      />
      <sn-password
        id="password"
        v-model="current_state_definition.fields[1].response"
        class="mb-4"
        :name="current_state_definition.fields[1].hint"
        :disabled="current_state_definition.fields[1].disabled"
        :hint="current_state_definition.fields[1].requirements"
        :disable-rules="true"
        :label="current_state_definition.fields[1].hint"
        :sn-input-props_error-messages="current_state_definition.fields[1].error ? current_state_definition.fields[1].error : []"
        @focus="clearErrors(1)"
        @blur="checkErrors(1)"
        @keydown.enter="keydownEnter()"
      />
      <sn-alert
        v-if="error"
        :message="errorMessages ? errorMessages[0] : ''"
        type="error"
        data-cy="login-error-message"
      />
      <sn-btn
        type="primary"
        style="width: 100%"
        aria-label="sign in"
        data-cy="sign-in-btn"
        size="large"
        text="Sign in"
        :loading="waitingRequests['sign_in']"
        :disabled="waitingRequest && !waitingRequests['sign_in']"
        @click="nextState(current_state_definition.primary_action);"
      />
      <div
        class="sn-body-2 forgot-password"
        data-cy="forgot-password-link"
        @click="nextState(current_state_definition.secondary_action);"
      >
        Forgot your password?
      </div>
    </div>
    <div v-else-if="state.includes('auth_input_email')">
      <div
        class="sn-h2 mb-6"
        data-cy="recover-password-title"
        :style="isMobile ? 'text-align: center' : ''"
      >
        Recover password
      </div>
      <div
        class="mb-6 sn-body-1"
        data-cy="recover-password-text"
      >
        It's okay, it happens to all of us. Let us help you recover your password.
      </div>
      <sn-label
        class="mb-2 sn-body-1"
        data-cy="recover-email-label"
        title="What is your email?"
      />
      <sn-text-field
        id="email"
        v-model="current_state_definition.fields[0].response"
        class="mb-8"
        type="email"
        data-cy="recover-email-field"
        :autofocus="true"
        :error-messages="current_state_definition.fields[0].error ? current_state_definition.fields[0].error : []"
        @focus="clearErrors(0)"
        @blur="checkErrors(0)"
        @keydown.enter="keydownEnter()"
      />
      <sn-alert
        v-if="error"
        :message="errorMessages ? errorMessages[0] : ''"
        type="error"
        data-cy="login-error-message"
      />
      <div :style="cssButtonRow">
        <sn-btn
          type="primary"
          :style="isMobile ? 'width: 100%' : 'width: 200px'"
          aria-label="continue"
          data-cy="continue"
          size="large"
          text="Continue"
          :loading="waitingRequests['connect']"
          :disabled="waitingRequest && !waitingRequests['connect']"
          @click="nextState(current_state_definition.primary_action)"
        />
        <sn-btn
          data-cy="back"
          :type="isMobile ? 'secondary' : 'text'"
          :style="isMobile ? 'width: 100%; margin-top: 10px;' : 'width: 200px'"
          :prepend-icon="isMobile ? '' : '$snArrowLeft'"
          text="Back"
          @click="nextState(current_state_definition.secondary_action);"
        />
      </div>
    </div>
    <div v-else-if="state.includes('auth_send_verification') || state.includes('auth_send_verification_email_only')">
      <div
        class="sn-h2 mb-6"
        data-cy="send-code-message"
        :style="isMobile ? 'text-align: center' : ''"
      >
        For full access, where would you like us to send your verification code?
      </div>
      <sn-radio-group
        v-if="current_state_definition.fields[0].options"
        v-model="current_state_definition.fields[0].response"
        required
        hide-details
        mandatory
        data-cy="radio-option"
        class="mt-2"
      >
        <sn-radio
          v-for="(option, index) in filteredOptions(current_state_definition.fields[0].options)"
          :key="`option-${index}`"
          :value="option.value"
          :label="option.label"
        />
      </sn-radio-group>
      <sn-alert
        v-if="error"
        :message="errorMessages ? errorMessages[0] : ''"
        type="error"
        data-cy="login-error-message"
      />
      <div :style="cssButtonRow + 'margin-top: 32px'">
        <sn-btn
          type="primary"
          :style="isMobile ? 'width: 100%' : 'width: 200px'"
          aria-label="continue"
          data-cy="continue"
          size="large"
          text="Continue"
          :loading="waitingRequests['send_code']"
          :disabled="waitingRequest && !waitingRequests['send_code']"
          @click="nextState(current_state_definition.primary_action)"
        />
        <sn-btn
          data-cy="back"
          :type="isMobile ? 'secondary' : 'text'"
          :style="isMobile ? 'width: 100%; margin-top: 10px;' : ''"
          :prepend-icon="isMobile ? '' : '$snArrowLeft'"
          text="Back"
          @click="(() => this.$router.go())"
        />
      </div>
    </div>
    <div v-else-if="state.includes('auth_waiting_for_verification')">
      <div
        class="sn-h2 mb-6"
        data-cy="code-sent-message"
        :style="isMobile ? 'text-align: center' : ''"
      >
        Your code was sent.<br>
        Please enter it below:
      </div>
      <sn-label
        class="mb-2 sn-body-1"
        data-cy="code-label"
        title="6 digit verification code"
      />
      <sn-text-field
        id="code"
        v-model="current_state_definition.fields[0].response"
        data-cy="code-field"
        :autofocus="true"
        :error-messages="current_state_definition.fields[0].error ? current_state_definition.fields[0].error : []"
        @focus="clearErrors(0)"
        @blur="checkErrors(0)"
        @keydown.enter="keydownEnter()"
      />
      <sn-checkbox
        v-model="current_state_definition.fields[1].response"
        class="mt-2"
        :label="current_state_definition.fields[1].label"
        simple
      />

      <sn-alert
        v-if="error"
        :message="errorMessages ? errorMessages[0] : ''"
        type="error"
        data-cy="code-error-message"
      />
      <div :style="cssButtonRow + 'margin-top: 32px'">
        <sn-btn
          type="primary"
          :style="isMobile ? 'width: 100%' : 'width: 200px'"
          aria-label="verify"
          data-cy="verify-code-btn"
          size="large"
          text="Verify code"
          :loading="waitingRequests['verify']"
          :disabled="waitingRequest && !waitingRequests['verify']"
          @click="nextState(current_state_definition.primary_action);"
        />
        <sn-btn
          data-cy="back"
          :type="isMobile ? 'secondary' : 'text'"
          :style="isMobile ? 'width: 100%; margin-top: 10px;' : ''"
          :prepend-icon="isMobile ? '' : '$snArrowLeft'"
          text="Back"
          @click="nextState(current_state_definition.secondary_action);"
        />
      </div>
    </div>
    <div v-else-if="state.includes('new_recipient_account')">
      <div
        class="sn-h2 mb-6"
        data-cy="contact-lender-message"
        :style="isMobile ? 'text-align: center' : ''"
      >
        Please contact your lender to verify your email address.
      </div>
      <sn-alert
        v-if="error"
        :message="errorMessages ? errorMessages[0] : ''"
        type="error"
        data-cy="email-error-message"
      />
      <div :style="cssButtonRow + 'margin-top: 32px'">
        <sn-btn
          type="primary"
          :style="isMobile ? 'width: 100%' : 'width: 200px'"
          aria-label="start"
          data-cy="start-over-btn"
          size="large"
          text="Start over"
          :loading="waitingRequests['start']"
          :disabled="waitingRequest && !waitingRequests['start']"
          @click="nextState(current_state_definition.primary_action);"
        />
      </div>
    </div>
    <div v-else-if="state.includes('auth_complete')">
      <div
        class="sn-h2 mb-6"
        :style="isMobile ? 'text-align: center' : ''"
      >
        You're all set!
      </div>

      <div :style="cssButtonRow + 'margin-top: 32px'">
        <sn-btn
          type="primary"
          :style="isMobile ? 'width: 100%' : 'width: 200px'"
          aria-label="complete"
          data-cy="complete"
          size="large"
          text="Continue"
          :loading="waitingRequests['complete']"
          :disabled="waitingRequest && !waitingRequests['complete']"
          @click="nextState(current_state_definition.primary_action);"
        />
        <sn-btn
          data-cy="password-reset"
          :type="isMobile ? 'secondary' : 'text'"
          :style="isMobile ? 'width: 100%; margin-top: 10px;' : ''"
          text="Reset password"
          @click="nextState(current_state_definition.additional_actions[0]);"
        />
      </div>
    </div>
  </div>
  <!-- This block handles all other state combinations -->
  <div v-else>
    <div
      v-if="current_state_definition.title"
      class="sn-h2 mb-6"
      :style="isMobile ? 'text-align: center' : ''"
    >
      {{ current_state_definition.title }}
    </div>
    <div
      v-if="current_state_definition.subtitle"
      class="sn-body-1 mb-6"
    >
      {{ current_state_definition.subtitle }}
    </div>
    <div
      v-for="(field,index) in current_state_definition.fields"
      :key="'field-' + index"
    >
      <div
        v-if="field.text"
        id="content"
        v-html="field.text"
      />
      <sn-radio-group
        v-if="field.options"
        v-model="field.response"
        required
        hide-details
        mandatory
        data-cy="lo-list"
        class="mt-2"
      >
        <sn-radio
          v-for="(option, index) in filteredOptions(field.options)"
          :key="`option-${index}`"
          :data-cy="option.label ? getCypressTag(option.label + '-input') : 'radio-input'"
          :value="option.value"
          :label="option.label"
        />
      </sn-radio-group>
      <div v-if="!field.options && (field.type !== 'phone' && field.type !== 'password' && field.type !== 'checkbox')">
        <sn-label
          class="mb-2 sn-body-1"
          :title="field.hint"
          :data-cy="field.type + '-label'"
        />
        <sn-text-field
          v-model="field.response"
          :autofocus="index === 0"
          :class="'sel_input_' + index"
          :disabled="field.disabled"
          :error="field.ruleError"
          :error-messages="field.error ? field.error : []"
          :data-cy="field.type + '-field'"
          :name="field.hint"
          type="text"
          @focus="clearErrors(index)"
          @blur="checkErrors(index)"
          @keydown.enter="keydownEnter()"
        />
      </div>
      <div v-if="!field.options && field.type === 'phone'">
        <sn-label
          class="mb-2 sn-body-1"
          :title="field.hint"
        />
        <sn-text-field
          v-model="field.response"
          placeholder="(___) ___-____"
          :error="field.ruleError"
          :error-messages="field.error ? field.error : []"
          :disabled="field.disabled"
          :rules="[rules.phone]"
          @focus="clearErrors(index)"
          @blur="checkErrors(index)"
          @keydown.enter="keydownEnter()"
        />
      </div>
      <div v-if="field.type === 'password'">
        <sn-label
          class="mb-2 mt-4 sn-body-1"
          :title="field.hint"
          :data-cy="field.type + '-label'"
        />
        <sn-password
          :id="field.hint"
          v-model="field.response"
          class="mb-4"
          :name="field.hint"
          :disabled="field.disabled"
          :sn-input-props_hint="field.requirements"
          :disable-rules="true"
          :data-cy="field.type + '-field'"
          :sn-input-props_error-messages="field.error ? field.error : []"
          @focus="clearErrors(index)"
          @blur="checkErrors(index)"
          @keydown.enter="keydownEnter()"
        />
      </div>
      <sn-checkbox
        v-if="field.type === 'checkbox'"
        v-model="field.response"
        class="mt-2"
        :label="field.label"
        simple
      />
    </div>
    <sn-alert
      v-if="error"
      :message="errorMessages ? errorMessages[0] : ''"
      type="error"
      data-cy="login-error-message"
    />
    <div
      v-if="actions.length"
      :style="cssButtonRow + 'margin-top: 32px'"
    >
      <sn-btn
        v-for="(action, index) in actions.slice()"
        :key="'action-' + index"
        :type="action === current_state_definition.primary_action ? 'primary' : isMobile ? 'secondary' : 'text'"
        :style="isMobile && action === current_state_definition.primary_action ? 'width: 100%' : isMobile ? 'width: 100%; margin-top: 10px' : 'width: 200px'"
        :aria-label="action.label"
        :data-cy="getCypressTag(action.value)"
        size="large"
        :text="action.label"
        :loading="waitingRequests[action.value]"
        :disabled="waitingRequest && !waitingRequests[action.value]"
        @click="nextState(action);"
      />
    </div>
  </div>
</template>

<script>
import SnLoader from '~/components/common/snLoader'
import snValidationsService from '~/services/snValidationsService.js'
import cookie from 'js-cookie'
import ChooseLo from '../chooseLo.vue'
import {
  snAlert,
  snBtn,
  snCheckbox,
  snLabel,
  snPassword,
  snRadio,
  snRadioGroup,
  snTextField
} from '@ncino/snui'
import { getInvitation } from '~/services/invitationsService'

export default {

  name: 'StatefulAuth',

  components: {
    ChooseLo,
    SnLoader,
    snTextField,
    snLabel,
    snPassword,
    snBtn,
    snRadio,
    snRadioGroup,
    snAlert,
    snCheckbox
  },

  props: {
    activationCode: {
      type: String,
      default: '',
      required: false
    },
    autoLogin: {
      type: Boolean,
      default: false,
      required: false
    },
    userEmail: {
      type: String,
      default: '',
      required: false
    },
    init: {
      type: String,
      default: '',
      required: false
    },
    supressRedirects: {
      type: Boolean,
      default: false,
      required: false
    },
    authContext: {
      type: String,
      default: '',
      required: false
    },
    inviteGuid: {
      type: String,
      default: '',
      required: false
    },
    currentState: {
      type: Object,
      default: () => { return { fields: [] } },
      required: false
    },
    initState: {
      type: String,
      default: 'init',
      required: false
    }
  },

  data () {
    return {
      email: null,
      token: null,
      current_state_definition: this.currentState,
      actions: {},
      state: this.initState,
      titleText: '',
      subtitleText: '',
      values: [],
      error: false,
      errorMessages: [],
      waitingRequest: false,
      waitingRequests: {},
      rules: {
        email: (value) => {
          if (!value) return 'We need your email in order to help you sign in'
          return snValidationsService.email(value) || 'Invalid e-mail'
        },
        phone: (value) => {
          return snValidationsService.phone(value) || 'Invalid phone'
        }
      }
    }
  },

  computed: {
    authParams () {
      return {
        from_web: true,
        auth_context: this.authContext,
        invite_guid: this.inviteGuid
      }
    },
    isMobile () {
      return this.$vuetify.breakpoint.mdAndDown
    },
    isClosingPortal () {
      return this.authContext === 'closing_portal'
    },
    isEvault () {
      return this.authContext === 'evault'
    },
    isPartnerLanding () {
      return this.authContext === 'partner_landing'
    },
    isSkippableAuthContext () {
      return ['closing_portal', 'evault'].includes(this.authContext)
    },
    cssButtonRow () {
      if (this.$vuetify.breakpoint.mdAndDown) {
        return `
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          margin: 0 -5px -5px;
        `
      } else {
        return `
          background-color: #F3F5F7;
          display: flex;
          flex-direction: row-reverse;
          justify-content: space-between;
          align-items: center;
          margin: 0 -56px -56px;
          padding: 32px;
          border-bottom-left-radius: inherit;
          border-bottom-right-radius: inherit;
        `
      }
    }
  },

  // Usually the titles for each state are displayed in a parent component so this controls that display
  watch: {
    titleText () {
      this.$emit('title', this.titleText)
    },
    subtitleText () {
      this.$emit('subtitle', this.subtitleText)
    },
    state (newVal) {
      this.$emit('change-state', this.state)
      if (this.state === 'auth_sign_in' && this.$route && this.$route.query.email) {
        this.current_state_definition.fields[0].response = this.$route.query.email
      }
      if (this.state === 'auth_sign_in' && this.$route && this.$route.query.invitation) {
        this.fetchInvitationInformation()
      }
    },
    init (newVal) {
      if (newVal === 'start') {
        this.getStarted(true)
      }
    }
  },

  created () {
    if (this.isPartnerLanding) {
      this.setActions()
    } else {
      this.getStarted(this.init === 'start')
    }
    if (this.autoLogin) {
      if (window.PasswordCredential) {
        navigator.credentials.get({
          password: true,
          mediation: 'required'
        }).then(c => {
          if (c) {
            this.current_state_definition.fields[0].response = c.id
            this.current_state_definition.fields[1].response = c.password
            this.nextState()
          }
        }).catch(error => {
          // various browsers throw a NotSupportedError if they don't support credentials
          console.log('credential management is not supported')
          return error
        })
      }
    }
  },
  beforeDestroy () {
    window.removeEventListener('beforeunload', this.confirmLeavePage)
  },

  methods: {
    keydownEnter () {
      if (!this.waitingRequest) {
        this.nextState()
      }
    },

    handleServicerChoice (actionValue, selectedSac) {
      const action = {
        key: 'event',
        value: actionValue
      }
      if (actionValue === 'save_servicer') {
        this.current_state_definition.fields[0].response = selectedSac
        this.activationCode = selectedSac
        this.$emit('change-activation-code', selectedSac)
        this.nextState(action)
      }
      if (actionValue === 'skip_selection') {
        this.current_state_definition.fields[0].response = this.activationCode
        this.nextState(action)
      }
    },

    createRequest (action) {
      // This function goes through the current state definition and creates a request
      // based on keys and values and the event based on the action passed to this function
      const request = this.authParams
      request.auth_guid = cookie.get('auth_guid')
      if (this.activationCode) { // pass this through as soon as possible otherwise redirect to borrower/signup
        request.activation_code = this.activationCode
      }
      if (this.state.includes('choose_expert')) {
        request.event = action.value
        request.activation_code = this.activationCode
        return request
      }
      if (action) {
        request.event = action.value
        if (this.current_state_definition.fields) {
          for (let x = 0; x < this.current_state_definition.fields.length; x++) {
            request[this.current_state_definition.fields[x].key] = this.current_state_definition.fields[x].response
          }
        }
      }
      return request
    },

    checkErrors (index, checkUndefined) {
      checkUndefined = false
      // Check the rules for different types of fields for validation of input

      // Check to see if we are looking at valid field
      if (this.current_state_definition.fields && this.current_state_definition.fields[index] === undefined) {
        return false
      }

      // Trim imput
      if (this.current_state_definition.fields[index].response !== '' && this.current_state_definition.fields[index].response !== undefined) this.current_state_definition.fields[index].response = this.current_state_definition.fields[index].response.trim()

      // Run custom validation
      if (this.rules[this.current_state_definition.fields[index].type]) {
        if (this.rules[this.current_state_definition.fields[index].type](this.current_state_definition.fields[index].response) !== true) {
          this.current_state_definition.fields[index].ruleError = true
          this.current_state_definition.fields[index].error = this.rules[this.current_state_definition.fields[index].type](this.current_state_definition.fields[index].response)
          return true
        }
      }
      return false
    },

    clearErrors (index) {
      this.$set(this.current_state_definition.fields[index], 'ruleError', false)
      if (this.current_state_definition.fields[index].response === undefined) {
        this.current_state_definition.fields[index].response = ''
      }

      let removeErrorMessage = true
      for (let x = 0; x < this.current_state_definition.fields.length; x++) {
        if (this.current_state_definition.fields[x].ruleError === true) {
          removeErrorMessage = false
        } else {
          this.current_state_definition.fields[x].error = ''
        }
      }
      if (removeErrorMessage === true) {
        this.errorMessages = []
      }
    },

    // Redirect to sessions controller with token
    finishedAuthentication () {
      this.$axios.post('/sessions', {
        token: this.token,
        email: this.email,
        authenticity_token: document.getElementsByName('csrf-token')[0].content,
        fromLogin: true,
        fromMobileFlow: true,
        fromMobileShare: (this.$route?.query?.from_mobile_share === 'true'),
        sac_id: this.sacId,
        user_id: this.userId,
        invitation: this.$route?.query?.invitation
      })
        .then(
          (response) => {
            if (!this.supressRedirects) {
              window.location = response.data
            }
            this.$emit('authenticated')
          }
        )
        .catch(
          (error) => {
            this.$sentry.captureException(error)
            console.log(error)
          }
        )
    },

    ping () {
      const url = '/auth/v1/ping/' + cookie.get('auth_guid')
      this.$axios.get(url)
        .then(
          (response) => {
            if (response.data.current_state !== 'auth_waiting_for_verification') {
              clearInterval(this.pingFunction) // Once we have moved forward in the flow this stops the ping requests from continuing
              window.removeEventListener('beforeunload', this.confirmLeavePage) // remove event listener warning user to not leave page
              if (this.state === 'auth_waiting_for_verification') {
                this.$axios.post('/auth/v1/sync', this.createRequest(null))
                  .then((response) => {
                    this.processAuthResponse(response)
                  })
              }
            }
          }
        )
    },

    nextState (action) {
      // ensure the ping and beforeunload listener are immediately cleared on state transition.
      clearInterval(this.pingFunction)
      window.removeEventListener('beforeunload', this.confirmLeavePage)

      if (action === undefined || action === null || action === '') {
        action = this.current_state_definition.primary_action
      }
      if (this.current_state_definition.fields && this.state !== 'auth_waiting_for_verification' && this.state.includes('choose_expert') && action === this.current_state_definition.primary_action) {
        let errorsExist = false
        for (let x = 0; x < this.current_state_definition.fields.length; x++) {
          // Make sure all the fields have input
          if (!this.current_state_definition.fields[x].response) {
            this.$set(this.current_state_definition.fields[x], 'ruleError', true)
            errorsExist = true
            this.error = true
            this.errorMessages = ['All fields are required']
          } else { // If any fields still have errors don't move to the next state
            errorsExist = this.checkErrors(x, true)
          }
        }
        if (errorsExist) {
          return
        }
      }
      this.setLoader(action, true)
      this.$axios.post('/auth/v1/sync', this.createRequest(action))
        .then(
          (response) => {
            this.setLoader(action, false)
            this.processAuthResponse(response)
          }
        )
    },

    setLoader (action, value) {
      this.waitingRequest = value
      if (action?.value) {
        this.waitingRequests[action.value] = value
      }
    },

    // Currently we are skipping the init state used on mobile
    getStarted (init = false) {
      // return
      cookie.set('new_user', true)
      this.$axios.post('/auth/v1/init', this.authParams)
        .then(
          (response) => { // this could be changed if we switch to the full state machine
            // we are using set instead of setting equal to in order to allow vue to responsively react to the changes of the values within the keys.
            // for more info: https://vuejs.org/v2/guide/reactivity.html#Change-Detection-Caveats
            const keys = Object.keys(response.data.global_action_definitions)
            keys.forEach(key => {
              this.$set(this.current_state_definition, key, response.data.global_action_definitions[key])
            })
            cookie.set('auth_guid', response.data.values.auth_guid)
            if (init) {
              this.nextState(response.data.global_action_definitions.primary_action)
            } else {
              this.nextState(response.data.global_action_definitions.secondary_action)
            }
          }
        )
    },

    setActions () {
      this.actions = []
      if (this.current_state_definition.primary_action) {
        this.actions = [this.current_state_definition.primary_action] // These are the buttons that are displayed
      }
      if (this.current_state_definition.secondary_action) {
        this.actions = this.actions.concat(this.current_state_definition.secondary_action)
      }
      if (this.current_state_definition.additional_actions) { // If there are additional actions besides main action
        this.actions = this.actions.concat(this.current_state_definition.additional_actions)
      }
    },

    setFields () {
      for (let x = 0; x < this.current_state_definition.fields.length; x++) {
        this.current_state_definition.fields[x].response = this.values[this.current_state_definition.fields[x].key] // set the response to default values
      }
    },

    setErrors () {
      this.errorMessages = [this.current_state_definition.error]
      if (this.errorMessages && this.errorMessages[0] !== '') this.error = true
      else {
        this.error = false
        this.errorMessages = []
      }
    },

    processAuthResponse (response) {
      // Set current state
      this.state = response.data.current_state
      // the state will be expired if the auth has completed or a time out, so the check for authenticated_data is necessary
      if (this.state === 'init') {
        this.$emit('title', 'With a little information, we\'ll save your work for you.')
        this.getStarted()
        return
      }
      if (this.state === 'auth_signed_in' && response.data.authenticated_data) {
        this.email = response.data.authenticated_data.user.email
        this.token = response.data.authenticated_data.token
        this.sacId = response.data.authenticated_data.sac_id
        this.userId = response.data.authenticated_data.user.id
        this.finishedAuthentication()
        return
      }

      // new_inputs_combined comes back if we don't recognize the email, activationCode is blank if we don't recognize the servicer
      // so we redirect them so they enter the servicer and then they can continue
      if (!this.isSkippableAuthContext && !this.supressRedirects && this.state === 'new_inputs_combined') {
        if ((!this.$route || !this.$route.path.includes('onboarding')) && this.activationCode !== '') {
          window.location.href = `/borrower/signup/${this.activationCode}`
          return
        } else if (this.activationCode === '') {
          window.location.href = '/borrower/signup/'
          return
        }
      }

      if (this.state === 'new_inputs_combined' && this.activationCode !== response.data.values.activation_code) {
        this.activationCode = response.data.values.activation_code
      } else if (this.state === 'new_complete') {
        this.nextState({
          value: 'complete'
        })
        return
      }

      this.values = response.data.values // gets current preset values such as email, phone name ect. These are displayed as helps to user if fields match
      const isEmailDisabled = this.current_state_definition.fields[0]?.disabled
      this.current_state_definition = response.data.current_state_definition || { fields: [] }
      this.setActions()
      this.setFields()
      this.setErrors()
      this.titleText = this.current_state_definition.title
      this.subtitleText = this.current_state_definition.subtitle
      this.fieldHeader = this.current_state_definition.field_header
      // If the request failed, the state gets reset. Add the disabled email state again
      this.current_state_definition.fields[0].disabled = isEmailDisabled
      // If we are waiting for verification we starting pinging so if they click on the email link we can advance
      if (this.state === 'auth_waiting_for_verification') {
        window.addEventListener('beforeunload', this.confirmLeavePage)
        this.pingFunction = setInterval(function () {
          this.ping()
        }.bind(this), response.data.current_state_definition.refresh_rate)
      }
      // If we are passed the email from another component already (such as borrowerInitial) this will move us forward in the state machine.
      if (this.userEmail && this.state === 'new_input_email' && response.data.success === true) {
        this.current_state_definition.fields[0].response = this.userEmail
        this.state = 'init'
        this.nextState()
      }
    },

    filteredOptions (options) {
      return options.filter((option) => option.label !== '')
    },

    snRadioOptions (options) {
      return this.filteredOptions(options)?.map(option => {
        return { label: option.label, text: option.label, value: option.value }
      })
    },

    confirmLeavePage (event) {
      // The following set up should work with the following browsers: IE, Edge, Chrome, Safari, Firefox.
      // **NOTE: Only IE allows you to customize the message of the alert. The other
      //         browsers use a standard message notifying the user of unsaved changes.***
      const message = 'Leaving this page now may disrupt the process. Do you still want to leave?'
      event.returnValue = message
      return message
    },

    getCypressTag (type) {
      switch (type) {
        case 'sign_in':
          return 'sign-in-btn'
        case 'save_servicer':
          return 'NEXT-btn'
        case 'I agree-input':
          return 'agree-input'
        case 'complete':
          return 'continue'
        default:
          return type
      }
    },
    fetchInvitationInformation () {
      const invitationGuid = this.$route?.query?.invitation
      getInvitation(invitationGuid)
        .then((data) => {
          const newStateDefinition = { ...this.current_state_definition }
          newStateDefinition.fields[0].response = data.email_address
          newStateDefinition.fields[0].disabled = true
          this.current_state_definition = newStateDefinition
        })
        .catch((err) => {
          // The invitation does not exist. Log and do nothing
          this.$sentry.captureException(err)
        })
    }
  }

}
</script>

<style scoped>
  .forgot-password {
    padding-top: 32px;
    font-weight: 600 !important;
    text-decoration: underline;
    cursor: pointer;
  }
</style>
